import {createRouter, createWebHistory} from 'vue-router'
import { generateRoutes } from '@/extensions/langRouter'

const NotFound = () => import('@/views/NotFound')

const routes = [
    {
        path: '/',
        paths: {en: '/'},
        name: 'home',
        component: () => import('@/views/Home')
    },
    {
        path: '/cabinet',
        paths: {en: '/firm'},
        name: 'office',
        component: () => import('@/views/Office')
    },
    {
        path: '/services',
        paths: {en: '/services'},
        name: 'services',
        component: () => import('@/views/Services')
    },
    {
        path: '/rh',
        paths: {en: '/hr'},
        name: 'hr',
        component: () => import('@/views/Hr')
    },
    {
        path: '/partenaires',
        paths: {en: '/partners'},
        name: 'advantages',
        component: () => import('@/views/Advantages')
    },
    {
        path: '/carrieres',
        paths: {en: '/careers'},
        name: 'careers',
        component: () => import('@/views/Careers')
    },
    {
        path: '/non-responsabilite',
        paths: {en: '/disclaimer'},
        name: 'disclaimer',
        component: () => import('@/views/Disclaimer')
    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const localizedRoutes = generateRoutes(routes, 'fr', ['en'])

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: "smooth"
            }
        }
        return { top: 0, behavior: 'smooth' }
    }
})

export default router
