<template>
  <div v-if="mq.mdPlus" class="navbar">
    <div class="navbar__secondary">
      <LangRouterLink :to="{name:'advantages'}">{{
          $beet.options.general.nav.perks
        }}
      </LangRouterLink>
      <LangRouterLink :to="{name:'careers'}">{{
          $beet.options.general.nav.careers
        }}
      </LangRouterLink>
      <div @click="$i18n.locale = otherLangSmall"> {{ otherLang }}</div>

    </div>
    <div class="navbar__main">
      <LangRouterLink :to="{name:'home'}"><img class="navbar__logo" src="@/assets/logo_simple.svg" alt="Veilleux">
      </LangRouterLink>
      <div class="navbar__links">
        <LangRouterLink :to="{name:'office'}" class="navbar__link">{{ $beet.options.general.nav.firm }}</LangRouterLink>
        <LangRouterLink :to="{name:'services'}" class="navbar__link">{{
            $beet.options.general.nav.services
          }}
        </LangRouterLink>
        <button @click.stop="$store.dispatch('openFamilyOffice')" class="navbar__link">
          {{ $beet.options.general.nav.dvfo.label }}
        </button>
        <LangRouterLink :to="{name:'hr'}" class="navbar__link">{{
            $beet.options.general.nav.humanResources
          }}
        </LangRouterLink>

        <button class="navbar__button --ml-full button" @click="scrollBottom">{{
            $beet.options.general.nav.contact
          }}
        </button>
        <a href="https://portail.dvsf.ca" target="_blank" class="button__portal --nav">
          {{ $t('portal') }}
          <Icon id="arrow"/>
        </a>
      </div>
    </div>
  </div>

  <div class="navbar__mobile" v-else>
    <LangRouterLink :to="{name:'home'}" @click="menuOpened = false"><img class="navbar__logo"
                                                                         src="@/assets/logo_simple.svg" alt="Veilleux">
    </LangRouterLink>
    <div class="navbar__content" v-if="!menuOpened">
      <button class="navbar__contact" @click="scrollBottom">
        <Icon id="contact"/>
      </button>
      <button class="navbar__menu-button" @click="menuOpened = true">
        <Icon id="menu"/>
      </button>
    </div>
    <button v-else class="navbar__menu-button -smaller" @click="menuOpened = false">
      <Icon id="close"/>
    </button>
    <div class="navbar__menu" :class="{'-opened': menuOpened}">
      <div v-if="menuOpened">
        <lang-router-link class="button button__video navbar__mobile-button"
                          :to="{ name: 'home', params: { video: true } }" @click="menuOpened = false">
          <div class="home__header-icon">
            <Icon id="play"/>
          </div>
          <span>{{ $beet.options.general.nav.video }}</span>
        </lang-router-link>
        <LangRouterLink :to="{name:'office'}" class="navbar__mobile-link" @click="menuOpened = false">
          {{ $beet.options.general.nav.firm }}
        </LangRouterLink>
        <LangRouterLink :to="{name:'services'}" class="navbar__mobile-link" @click="menuOpened = false">
          {{ $beet.options.general.nav.services }}
        </LangRouterLink>
        <button @click.stop="$store.dispatch('openFamilyOffice')" class="navbar__mobile-link">
          {{ $beet.options.general.nav.dvfo.label }}
        </button>
        <LangRouterLink :to="{name:'hr'}" class="navbar__mobile-link" @click="menuOpened = false">
          {{ $beet.options.general.nav.humanResources }}
        </LangRouterLink>
        <LangRouterLink :to="{name:'advantages'}" class="navbar__mobile-link" @click="menuOpened = false">
          {{ $beet.options.general.nav.perks }}
        </LangRouterLink>
        <LangRouterLink :to="{name:'careers'}" class="navbar__mobile-link" @click="menuOpened = false">
          {{ $beet.options.general.nav.careers }}
        </LangRouterLink>
        <div class="navbar__lang" @click="$i18n.locale = otherLangSmall"> {{ otherLang }}</div>
        <button class="button navbar__mobile-contact" @click="scrollBottom">{{
            $beet.options.general.nav.contact
          }}
        </button>
        <a href="https://portail.dvsf.ca" target="_blank" class="button__portal --nav">
          {{ $t('portal') }}
          <Icon id="arrow"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {LANG} from '@/helpers/Constants'

export default {
  name: 'SiteNav',
  inject: ['mq', 'emitter'],
  data() {
    return {
      menuOpened: false,
    }
  },
  computed: {
    beet() {
      return this.$beet
    },
    otherLangSmall() {
      return this.$i18n.locale === 'fr' ? 'en' : 'fr'
    },
    otherLang() {
      return this.$i18n.locale === 'fr' ? LANG.en : LANG.fr
    }
  },
  methods: {
    scrollBottom() {
      this.menuOpened = false
      this.$emit('scrollBottom')
      //window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    }
  },
  watch: {
    menuOpened() {
      this.menuOpened ? document.body.classList.add('--lock') : document.body.classList.remove('--lock')
    }
  }
}
</script>
