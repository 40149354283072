<template>
  <Transition name="fade">
    <SiteNav v-if="$beet.isReady" @scrollBottom="scrollBottom"/>
  </Transition>
  <router-view v-slot="{ Component }">
    <Transition name="fade">
      <component v-if="$beet.isReady" :is="Component"/>
    </Transition>
  </router-view>
  <Transition name="fade">
    <div v-if="$beet.isReady" ref="footer">
      <Footer/>
      <Gdpr :options="{backgroundColor: '#ffffff', color: '#032d59'}"/>
      <FamilyTransition/>
    </div>
  </Transition>
</template>

<script>
import Gdpr from '@/components/gdpr/Gdpr.vue'
import FamilyTransition from '@/components/FamilyTransition.vue'
import Footer from '@/components/Footer'
import SiteNav from '@/components/SiteNav'
import {useLangRouter} from '@/extensions/langRouter'

export default {
  name: 'App',
  setup() {
    useLangRouter()
  },
  components: {FamilyTransition, Footer, Gdpr, SiteNav},
  methods: {
    scrollBottom() {
      this.$refs['footer'].scrollIntoView({behavior: 'smooth'})
    }
  },
  watch: {
    '$i18n.locale': {
      handler: function (to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    }
  }
}
</script>
