<template>
  <div class="family-load" v-if="$store.state.familyTransition">
    <div class="family-load__left" :class="{'-closed' : familyTransitionEnd}"/>
    <div class="family-load__right" :class="{'-closed' : familyTransitionEnd}"/>

    <transition name="fade" mode="out-in">
      <div class="family-load__label" v-if="familyTransitionEnd"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'FamilyTransition',
  data() {
    return {
      familyTransitionEnd: false
    }
  },
  methods: {
    transition() {
      setTimeout(() => {
        this.familyTransitionEnd = true

        setTimeout(() => {
          let url = new URL(this.$beet.options.general.nav.dvfo.link)
          if (this.$i18n.locale === 'en') url.pathname =  '/en'
          window.location.replace(url.toString())
        }, 1050)
      }, 50)
    }
  },
  watch: {
    '$store.state.familyTransition'(to) {
      if (to) this.transition()
    }
  }
}
</script>