export default {
    home: 'accueuil',
    about: 'a-propos',
    secure: 'Portail sécurisé',
    portal: 'Portail',
    family: {
        header: {
            title: 'DAVID VEILLEUX',
            subtitle: 'FAMILY OFFICE'
        },
        hat: 'BUREAU FAMILIAL',
        title: 'ASSUREZ LA PROSPÉRITÉ DE VOTRE FAMILLE',
        text: 'Adaptées à votre patrimoine unique, nos stratégies d\'assurance valorisent votre patrimoine et soutiennent son épanouissement pendant des générations.',
        cta: 'CRÉEZ VOTRE PROPRE CHEMIN',
        relax: 'DÉTENDEZ-VOUS PENDANT QUE NOUS PRÉPARONS VOTRE EXPÉRIENCE',
        nav: 'FAMILY OFFICE',
        footer: 'DÉCOUVREZ DAVID VEILLEUX BUREAU FAMILIAL',
    }
}
