import {createStore} from 'vuex'

export default createStore({
    state: {
        familyTransition: false
    },
    mutations: {
        openFamilyOffice(state, isOpen = true) {
            state.familyTransition = isOpen
        }
    },
    actions: {
        openFamilyOffice(context, isOpen = true) {
            context.commit('openFamilyOffice', isOpen)
        }
    }
})
